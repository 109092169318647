import React from 'react'
import { withRouter, useLocation } from 'react-router-dom';
import slugify from 'react-slugify';

import { useStore } from '../../hooks-store/store';

import Loader from '../../components/Loader/Loader';
import SectionLogin from "../../sections/Login/Login";

import styles from "./Login.module.css";

const useQuery = (param) => {
	const search = useLocation().search;
	const dt = new URLSearchParams(search).get(param);
	return dt
}

const Login = (props) => {
	const dispatch = useStore(false)[1]

	let queryAuth = useQuery("auth");
	let queryPreview = useQuery("preview");
	let queryOrg = useQuery("organization");
	let queryBId = useQuery("board_id");
	let queryBnum = useQuery("board_number");
	let queryBname = useQuery("board_name");
	let queryBtoken = useQuery("token");

	const data = {
		isPreview: queryPreview,
		key: queryBtoken,
		project: {
			id: queryBId,
			name: queryBname,
			number: queryBnum,
			organization: queryOrg
		}
	}

	const setLogin = () => {
		dispatch('LOGIN', data);

		props.history.replace(`${queryPreview !== "true" ? "preview-board" : "board"}/${slugify(queryOrg)}/${slugify(queryBname)}/${slugify(queryBnum)}/${queryBId}`)
	}

	if (queryAuth && String(queryPreview) && queryOrg && queryBId && queryBnum && queryBname && queryBtoken) {
		setLogin()
		return (
			<div className={styles.loaderContainer}>
				<Loader loading={true} />
			</div>
		)
	} else {
		return (
			<SectionLogin />
		)
	}
}

export default withRouter(Login)
