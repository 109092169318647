import React from "react";
import { Redirect, Route } from "react-router-dom";
import slugify from 'react-slugify';

import { useStore } from "../hooks-store/store";

const PrivateRoute = (props) => {
	const state = useStore()[0]

	const url = `${state.project.project.organization ? slugify(state.project.project.organization) : "-"}/${slugify(state.project.project.name)}/${slugify(state.project.project.number)}/${state.project.project.id}`
	return (
		<>
			<Route
				render={(props) => {
					if (state.isLoggedIn && state.project.project.id){
						if ((state.isPreview && state.isPreview !== "true")) {
							return <Redirect to={`/preview-board/${url}`} />;
						} else {
							return <Redirect to={`/board/${url}`} />;
						}
					} else {
						return <Redirect to="/login" />;
					}
				}}
			/>
		</>

	);
};

export default PrivateRoute;
