import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import "./InputMotion.scss";

export default function InputMotion(props) {
	const [isEeye, setEye] = useState(false);
	let eye, type;
	if (props.type === "password" && props.isEye) {
		eye = !isEeye ? <FaEyeSlash className="eye eye-black" onClick={() => setEye(true)} /> : <FaEye className="eye" onClick={() => setEye(false)} />;
		type = !isEeye ? "password" : "text";
	} else {
		type = props.type ? props.type : "text";
	}

	let status = props.status === "required" || !props.status ? "isRequired" : "";
	let labelStatus = props.status === "required" || !props.status ? "__isrequired" : "";
	let defaultClass, isInput;
	if (props.multiline) {
		defaultClass = 'form__motion__textarea'
		isInput = <textarea type={type} id={props.id} defaultValue={props.value ? props.value : ""} rows="4" cols="50" className={["form__input", status].join(" ")} autoComplete="off" placeholder=" " onChange={props.onChange} onBlur={props.onBlur} onKeyPress={props.onEnter} />;
	} else {
		defaultClass = 'form__motion'
		isInput = <input type={type} id={props.id} defaultValue={props.value ? props.value : ""} className={["form__input", status].join(" ")} autoComplete="off" placeholder=" " onChange={props.onChange} onBlur={props.onBlur} onKeyPress={props.onEnter} disabled={props.disable}/>;
	}

	return (
		<>
			<div className={defaultClass}>
				{isInput}
				<label htmlFor={props.id} className={["form__label", labelStatus].join(" ")}>
					{props.label} <span>*</span>
				</label>
				{eye}
				{props.desc && <span className="form__desc">{props.hasError ? `Please enter ${props.label}` : props.desc}</span>}
			</div>
			{props.error && props.error === "required" && <span className="form__desc__error">{props.errorTxt}</span>}
		</>
	);
}
