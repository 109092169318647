import React from "react";

import DesktopView from "./DesktopView/Desktop";
import TabletView from "./TabletView/Tablet";
import { useMediaQuery } from "react-responsive";

function Section6() {
	const isDesktopOrLaptop = useMediaQuery({
		query: "(min-device-width: 1025px)",
	});
	const isTablet = useMediaQuery({ query: "(max-width: 1024px)" });

	if (isDesktopOrLaptop) {
		return <DesktopView />;
	} else if (isTablet) {
		return <TabletView />;
	}
}

export default Section6;
