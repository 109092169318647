import React from "react";
import { useMediaQuery } from "react-responsive";

import DesktopView from "./HeaderDesktop";
import MobileView from "./HeaderMobile";

function Header() {
	const isDesktopOrLaptop = useMediaQuery({
		query: "(min-width: 1024px)",
	});
	const isTablet = useMediaQuery({ query: "(max-width: 1024px)" });
	const isMobileDevice = useMediaQuery({
		query: "(max-width: 867px)",
	});

	if (isMobileDevice) {
		return <MobileView />;
	} else if (isTablet) {
		return <DesktopView />;
	} else if (isDesktopOrLaptop) {
		return <DesktopView />;
	}
}

export default Header;
