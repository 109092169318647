import React from "react";
import { useMediaQuery } from "react-responsive";
import loadable from "@loadable/component";

const DesktopView = loadable(() => import("./DesktopView/DesktopView"));
const TabletView = loadable(() => import("./MobileView/MobileView"));

function Section4() {
	const isDesktopOrLaptop = useMediaQuery({
		query: "(min-device-width: 1025px)",
	});
	const isTablet = useMediaQuery({ query: "(max-width: 1024px)" });
	const isMobileDevice = useMediaQuery({
		query: "(max-device-width: 760px)",
	});

	if (isDesktopOrLaptop) {
		return <DesktopView />;
	} else if (isTablet) {
		return <DesktopView />;
	} else if (isMobileDevice) {
		return <TabletView />;
	}
}

export default Section4;
