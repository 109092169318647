import axios from "axios";
import config from "../lib/Config";
import { useStore } from "../hooks-store/store";
const LoginApi = () => {
	const base_api = config.base_api;
	const state = useStore()[0]
	const token = state?.project?.key;

	const signIn = (data) => {
		return axios.post(base_api + `board-login/`, data, {
			params: {},
		});
	}

	const signOut = () => {
		// let useToken;
		// if (project.dataProject.isAuth) {
		// 	useToken = project.dataProject.isToken;
		// } else {
		// 	useToken = token;
		// }

		return axios.post(base_api + `board-logout/`, {
			params: {},
			headers: {
				Authorization: "Token " + token,
				"Content-Type": "application/json",
			},
		});
	}

	return {signIn, signOut}
}

export default LoginApi;
