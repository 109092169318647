import Cookies from "js-cookie";
import { initStore } from "./store";

const configureStore = () => {
    const actions = {
        LOGIN: (currState, project) => {
            Cookies.set("bdky", project.key);
            Cookies.set("bdprj", project.project?.id);
            Cookies.set("bdprjn", project.project?.name);
            Cookies.set("bdprjnu", project.project?.number);
            Cookies.set("bdorgn", project.project?.organization);
            return {
                project: project,
                isLoggedIn: true,
                isPreview: project.isPreview
            }
        },
        LOGOUT: () => {
            Cookies.remove("bdky");
            Cookies.remove("bdprj");
            Cookies.remove("bdprjn");
            Cookies.remove("bdprjnu");
            Cookies.remove("bdorgn");
            return {
                project: {},
                isLoggedIn: false
            }
        }
    }

    const key = Cookies.get("bdky");
    const pid = Cookies.get("bdprj");
    const pn = Cookies.get("bdprjn");
    const pnu = Cookies.get("bdprjnu");
    const porg = Cookies.get("bdorgn");

    initStore(actions, {
        project: {
            key: key,
            project: {
                id: pid,
                name: pn,
                number: pnu,
                organization: porg
            }
        },
        isLoggedIn: (key && pid && pn && pnu && porg) ? true : false,
        isPreview: false
    })
}

export default configureStore