const UseLinkChecked = (link) => {
    let linkUrl
    if (link && link !== "false") {
        if (['http'].find(u => link.includes(u))) {
            if (link.includes('www')) {
                linkUrl = link
            } else {
                linkUrl = `${link}`
            }
        } else if (!['http'].find(u => link.includes(u))) {
            if (link.includes('www')) {
                linkUrl = `//${link}`
            } else {
                linkUrl = `//.${link}`
            }

        } else {
            linkUrl = `//.${link}`
        }
    } else {
        linkUrl = false
    }
    return linkUrl
}
export default UseLinkChecked
