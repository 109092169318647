import projectApi from "../api/ProjectApi";

const countAssetCounter = (asset, asset_link) => {
    const { postAssetCounter } = projectApi()

    const setAssetCounter = (asset, asset_link) => {
        if (asset && asset_link) {
            postAssetCounter({
                asset: asset,
                asset_link: asset_link
            })
                .then((res) => {
                    // do something
                })
                .catch((err) => {
                    console.log(err.response);
                });
        }
    }

    return { setAssetCounter }
}
export default countAssetCounter