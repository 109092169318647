import React, { useEffect } from 'react';

export default function Scheduler(props) {
	const MINUTE_MS = 180000;

	useEffect(() => {
		if (props.isRunning) {
			const interval = setInterval(() => {
				props.action();
			}, MINUTE_MS);

			return () => clearInterval(interval);
		}
	}, [props]);
	return <></>;
}
