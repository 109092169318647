import React from 'react'
import { useStore } from '../../../hooks-store/store'

import Loader from "../../../components/Loader/Loader"

import Section1 from "../../../sections/Section1/Section1";
import Section2 from "../../../sections/Section2/Section2";
import Section3 from "../../../sections/Section3/Section3";
import Section4 from "../../../sections/Section4/Section4";
import Section5 from "../../../sections/Section5/Section5";
import Section6 from "../../../sections/Section6/Section6";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";

import styles from "../Home.module.css";

export default function Mobile() {
    const state = useStore()[0]

	const template = state.template
	const messages = state.messages
    return (
        <>
            {
                state.dataProject && state.messages && state.organization ?
                    <>
                        <Header />
                        <div className={[styles.contentMobile, styles.Section1Mobile].join(' ')}>
                            <Section1 />
                        </div>
                        <div className="ContainerTab">
                            <div className={styles.contentMobile}>
                                <Section2 />
                            </div>
                        </div>
                        <div className="ContainerTab section">
                            <div className={styles.contentMobile}>
                                <Section3 />
                            </div>
                            {[2, 3].includes(template) && (
                                <div className={styles.contentMobile}>
                                    <Section4 messages={messages} />
                                </div>
                            )}
                            <div className={styles.contentMobile}>
                                <Section5 />
                            </div>
                            {template === 3 && (
                                <div className={styles.contentMobile}>
                                    <Section6 />
                                </div>
                            )}
                        </div>
                        <Footer />
                    </> :
                    <div className={styles.loaderContainer}>
                        <Loader loading={true} />
                    </div>
            }
        </>
    )
}
