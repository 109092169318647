import React from 'react'
import { Slide } from "react-awesome-reveal";

import { useStore } from '../../../hooks-store/store';

import MenuItems from "../../../components/MenuItems/MenuItems";

import countCounter from '../../../lib/countCounter';

const DesktopView = (props) => {
	const {setCounter} = countCounter()
	const { menuAdvanced, menuStandard, menuBasic } = useStore()[0];

	const state = useStore()[0]
	const data = state.dataProject;
	const language = state.language


	let cards;
	let menuStyle;
	let wrapperStyle = {};
	if (data && data.sections) {
		const template = data.template.id;
		let templateMenu
		if (template === 1) {
			menuStyle = 'MenuesChild-basic';
			templateMenu = menuBasic
		} else if (template === 2) {
			menuStyle = 'MenuesChild-standard';
			templateMenu = menuStandard
		} else if (template === 3) {
			templateMenu = menuAdvanced
		}

		if (data.sections[2]) {
			const sec = data.sections[2];
			const items = sec?.text_holders

			cards = items?.map((s, i) => {
				let activeLangH = s.text_holder_languages?.findIndex(la => la.language === language)
				let activeLangC = s.text_contents?.findIndex(la => la.language === language)
				activeLangH = String(activeLangH) ? activeLangH : 0
				activeLangC = String(activeLangC) ? activeLangC : 0

				const c = s.text_holder_languages[activeLangH];
				const btnContent = c ? (c.editable_text_holder ? c.editable_text_holder : c.name) : "";
				const l = s.text_contents[activeLangC];
				if (!s.text_contents[activeLangC]?.is_hidden) {
					return <MenuItems key={`${c?.name}-${i}`} Icon={s.icon?.file || templateMenu[i].menu_icon} style={templateMenu[i].menu_style} MenuText={btnContent} Index={i} Link={l?.content} onClick={()=>setCounter(l?.id)}/>;
				} else {
					return null
				}
			});
			if (cards?.length) {
				const totalCard = cards.filter((itm)=> !!itm)
				const colGrid = totalCard.length % 2 === 0 ? totalCard.length / 2 : Math.round(totalCard.length / 2)
				wrapperStyle = { gridTemplateColumns: `repeat(${colGrid}, auto)` }
			}
		}
	}
	return (
		<Slide style={{
			width: "100%", height: "100%"
		}} triggerOnce>
			<div className="Menues">
				<div className={["MenuesChild", menuStyle].join(' ')} style={wrapperStyle}>{cards}</div>
			</div>
		</Slide>
	)
}

export default React.memo(DesktopView)
