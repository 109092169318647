import React from 'react'
import { Button } from "react-bootstrap";
import { Slide } from "react-awesome-reveal";

import { useStore } from '../../../hooks-store/store';

import linkChecked from "../../../helper/UseLinkChecked"
import countCounter from '../../../lib/countCounter';

import styles from "./MobileView.module.css";

const MobileView = (props) => {
	const {setCounter} = countCounter()
    const state = useStore()[0]

    const data = state.dataProject;
    const language = state.language
    const o = state.organization;

    const public_background_color = o?.public_background_color ? o.public_background_color : "#1a4a97";
    const public_content_color = o?.public_content_color ? o.public_content_color : "#ffffff";
    let documentation;
    if (data && data.sections) {
        if (data.sections[1]) {
            const sec = data.sections[1];

            documentation = sec?.text_holders?.map((s, i) => {
                let activeLangH = s.text_holder_languages?.findIndex(la => la.language === language)
                let activeLangC = s.text_contents?.findIndex(la => la.language === language)
                activeLangH = String(activeLangH) ? activeLangH : 0
                activeLangC = String(activeLangC) ? activeLangC : 0

                const c = s.text_holder_languages[activeLangH];
                const l = s.text_contents[activeLangC];

                const btnContent = c ? (c.editable_text_holder ? c.editable_text_holder : c.name) : "";
                let content;
                let html = (
                    <Button key={`${l?.id}-${i}`} className={l ? (!l?.content ? styles.btnM : "test") : styles.btnM} style={{ background: public_background_color, color: public_content_color, border: "none" }} onClick={()=>setCounter(l?.id)}>
                        <span>{btnContent}</span>
                    </Button>
                );
                if (l && l?.content) {
                    const link = linkChecked(l.content)
                    content = (
                        <a key={`${l.id}-${i}`} className={styles.btnM} href={link} target="_blank" rel="noopener noreferrer">
                            {html}
                        </a>
                    );
                } else {
                    content = html;
                }
                if (!s.text_contents[activeLangC]?.is_hidden) {
					return content;
				} else {
					return <></>
				}
            });
        }
    }
    return (
        <Slide
            style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: "100%"
            }}
            triggerOnce
        >
            <div className={styles.MenuList}>{documentation}</div>
        </Slide>
    )
}

export default React.memo(MobileView)
