import React, { useContext } from 'react';

import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faPhoneAlt, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Slide } from 'react-awesome-reveal';

import { useStore } from '../../../hooks-store/store';
import linkChecked from '../../../helper/UseLinkChecked';
import countCounter from '../../../lib/countCounter';
import Context from '../../../Context';

import styles from './Desktop.module.css';

const Desktop = (props) => {
	const context = useContext(Context);
	const { setCounter } = countCounter();

	const state = useStore()[0];
	const { h, w } = context;

	const data = state.dataProject;
	const language = state.language;
	const template = state.template;

	let itmLanguages = state?.languageItem?.filter((l) => l.language === state.language);
	let activeLanguage = itmLanguages ? itmLanguages[0] : state.languageItem[0];

	const o = state.organization;

	const site_background_color = o?.site_background_color ? o.site_background_color : '#ffb504';
	const site_content_color = o?.site_content_color ? o.site_content_color : '#000000';

	const groupPadStyle = {
		padding: h >= 1360 ? '0.25rem' : h < 1360 && h >= 768 ? '0.2rem' : `${h / 1000 / 4}rem`,
	};
	const padStyle = {
		padding: `${h / 1000 / 2}rem ${h / 1000}rem`,
	};
	const marginStyle = {
		marginBottom: `${h / 1000 / 4}rem`,
	};

	let btnPadStyle = `${h / 1000 / 4}rem ${h / 1000 / 2}rem`;
	let fS = 16;
	switch (true) {
		case w >= 2560 && h >= 1080:
			fS = 22;
			btnPadStyle = '1rem'
			break;
		case w >= 1920 && h >= 1080:
			fS = 18;
			btnPadStyle = '0.75rem 1rem'
			break;
		case h > 1360:
			fS = 16;
			break;
		case (h > 1000) & (h <= 1360):
			fS = 14;
			break;
		case h <= 1000 && h >= 768 && w >= 1280:
			fS = 13;
			break;
		case h <= 1000 && h > 768:
			fS = h / 100 + 5;
			break;
		case h <= 768:
			fS = h / 100 + 3;
			break;
		default:
			break;
	}

	const btnStyle = {
		borderColor: site_content_color,
		width: '100%',
		padding: `${btnPadStyle}`,
		height: `${h > 768 ? 'auto' : h <= 768 && h > 480 ? `${h / 10 / 2.25 <= 24 ? h / 10 / 2.25 : 30}px` : `${h / 10 / 2.5}px`}`,
	};
	const btnTextStyle = {
		fontSize: `${fS}px`,
		lineHeight: `${fS >= 18 ? 24 : fS }px`,
	};
	const titleTextStyle = {
		marginBottom: h > 900 ? '0.5rem' : h < 900 && h > 768 ? '0.15rem' : '0',
	};
	const icStyle = {
		fontSize: `${fS}px`,
		height: 'auto',
		verticalAlign: 'middle',
	};

	let action = [];
	let contactPerson, contactNumber;
	if (data && data.sections) {
		if (data.sections[0]) {
			const sec = data.sections[0];
			sec?.text_holders?.forEach((s, i) => {
				let activeLangH = s.text_holder_languages?.findIndex((la) => la.language === language);
				let activeLangC = s.text_contents?.findIndex((la) => la.language === language);
				activeLangH = String(activeLangH) ? activeLangH : 0;
				activeLangC = String(activeLangC) ? activeLangC : 0;

				const t = s?.text_contents[activeLangC];
				const c = s?.text_holder_languages[activeLangH];
				const l = s?.text_contents[activeLangC];
				const btnContent = c ? (c.editable_text_holder ? c.editable_text_holder : c.name) : '';

				let html;
				if (i < 2) {
					if (i === 0) {
						contactPerson = (
							<span style={btnTextStyle} key={`${t?.id}-${i}`} onClick={() => setCounter(t?.id)}>
								{t?.content ? t?.content : '-'}
							</span>
						);
					} else if (i === 1) {
						contactNumber = (
							<span style={btnTextStyle} key={`${t?.id}-${i}`} onClick={() => setCounter(t?.id)}>
								{t?.content ? t?.content : '-'}
							</span>
						);
					}
				} else if (i > 1) {
					html = (
						<Button key={`${t?.id}-${i}`} style={btnStyle} onClick={() => setCounter(t?.id)}>
							<span style={btnTextStyle}>{btnContent}</span>
							<div className={styles.Svg}>
								<FontAwesomeIcon icon={faChevronRight} style={icStyle} />
							</div>
						</Button>
					);

					if (l && l?.content && l?.content !== '' && ['http', 'www'].find((v) => l?.content.includes(v))) {
						const link = linkChecked(l.content);
						if (!s.text_contents[activeLangC]?.is_hidden) {
							action.push(
								<a key={`${t?.id}-${i}`} href={link} alt='' target='_blank' rel='noreferrer'>
									{html}
								</a>
							);
						}
					} else {
						if (!s.text_contents[activeLangC]?.is_hidden) {
							action.push(html);
						}
					}
				}
			});
		}
	}

	const containerStyle = template === 1 ? styles.containerBasic : template === 2 ? styles.containerStandard : styles.container;

	return (
		<>
			{data && data.sections && (
				<Slide style={{ width: '100%', height: '100%' }} triggerOnce>
					<div
						className={[styles.Model, containerStyle].join(' ')}
						style={{ background: site_background_color, color: site_content_color, ...padStyle }}
					>
						<div className={styles.Card}>
							<div style={marginStyle} className={styles.Type}>
								<div style={{ ...btnTextStyle, ...groupPadStyle }} className={styles.FormGroup}>
									<span>{activeLanguage?.content.section1.fire}</span>
									<span>110</span>
								</div>
								<div style={{ ...btnTextStyle, ...groupPadStyle }} className={styles.FormGroup}>
									<span style={btnTextStyle}>{activeLanguage?.content.section1.police}</span>
									<span>112</span>
								</div>
								<div style={{ ...btnTextStyle, ...groupPadStyle }} className={styles.FormGroup}>
									<span style={btnTextStyle}>{activeLanguage?.content.section1.ambulance}</span>
									<span>113</span>
								</div>
							</div>
							<div style={marginStyle} className={styles.Contact}>
								<div className={styles.Type}>
									<div className={styles.FormGroup2}>
										<span style={{ ...btnTextStyle, ...titleTextStyle }} className={styles.FGCp}>
											{activeLanguage?.content.section1.cp_event}
										</span>
									</div>
									<div style={groupPadStyle} className={styles.FormGroup2}>
										<div className={styles.Svg}>
											<FontAwesomeIcon icon={faUser} style={icStyle} />
										</div>

										{contactPerson}
									</div>
									<div style={groupPadStyle} className={styles.FormGroup2}>
										<div className={styles.Svg}>
											<FontAwesomeIcon icon={faPhoneAlt} style={icStyle} />
										</div>
										{contactNumber}
									</div>
								</div>
							</div>
							<div className={styles.Button}>
								{action &&
									action.map((c) => {
										return c;
									})}
							</div>
						</div>
					</div>
				</Slide>
			)}
		</>
	);
};

export default React.memo(Desktop);
