import React, { useEffect, useLayoutEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import ReactGA from 'react-ga4';
import posthog from 'posthog-js';

import { useStore } from '../../hooks-store/store';

import Context from '../../Context';
import Content from './Desktop/Content';
import Desktop from './Desktop/Desktop';
import Mobile from './Desktop/Mobile';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const Home = (props) => {
	const { height: H, width: W } = useWindowDimensions();

	const projectId = props.match.params ? props.match.params.id : false;

	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-width: 1025px)',
	});
	const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });
	const isMobileDevice = useMediaQuery({
		query: '(max-width: 760px)',
	});

	const reactGa = () => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "DigiTavle" });
	};

	const state = useStore()[0];

	useEffect(() => {
		let isGASubs = true;
		if (isGASubs) {
			reactGa();
		}
		return () => {
			isGASubs = false;
		};
	}, []);

	useLayoutEffect(() => {
		if (process.env.REACT_APP_MODE === 'production') {
			posthog.capture('Screen', { width: W, height: H, browser: navigator.userAgent });
		}
	});

	let html, renderHtml;
	if (state.isLoggedIn && state.project.project.id && state.project.key) {
		if (isMobileDevice) {
			html = <Mobile id={projectId} />;
		} else if (isTablet) {
			html = <Desktop id={projectId} />;
		} else if (isDesktopOrLaptop) {
			html = <Desktop id={projectId} />;
		}
		renderHtml = <Content>{html}</Content>;
	} else {
		renderHtml = <></>;
		window.location.href = '/';
	}

	return <Context.Provider value={{ h: H, w: W }}>{renderHtml}</Context.Provider>;
};

export default React.memo(Home);
