import React from 'react';
import ReactDOM from 'react-dom';
import { PostHogProvider } from 'posthog-js/react';
import ReactGA from 'react-ga4';

import App from './App';

import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import configureAuthStore from './hooks-store/auth-store';
import configureProjectStore from './hooks-store/project-store';
import configureWeatherStore from './hooks-store/weather-store';
import configureLanguageStore from './hooks-store/language-store';
import configureHseIconStore from './hooks-store/hse-icon-store';

configureAuthStore();
configureProjectStore();
configureWeatherStore();
configureLanguageStore();
configureHseIconStore();

const options = {
	api_host: 'https://app.posthog.com',
};

if (process.env.REACT_APP_MODE === 'production') {
	ReactGA.initialize(process.env.REACT_APP_GA_ID);
}

ReactDOM.render(
	<React.StrictMode>
		{process.env.REACT_APP_MODE === 'production' ? (
			<PostHogProvider apiKey={process.env.REACT_APP_POSTHOG} options={options}>
				<App />
			</PostHogProvider>
		) : (
			<App />
		)}
	</React.StrictMode>,
	document.getElementById('root')
);
