import { initStore } from './store'

import userInjured from "../assets/section2/user-injured.svg"
import users from "../assets/section2/users.svg"
import fileMedical from "../assets/section2/file-medical.svg"
import exclamationTriangle from "../assets/section2/exclamation-triangle.svg"
import enviraLeaf from "../assets/section2/envira.svg"
import tasks from "../assets/section2/tasks.svg"
import exclamationCircle from "../assets/section2/exclamation-circle.svg"
import poll from "../assets/section2/poll-h.svg"
import medkit from "../assets/section2/medkit.svg"
import shield from "../assets/section2/shield-virus.svg"

const menuAdvanced = [
    {
        id: 0,
        menu_text: "SECURE JOB ANALYSIS",
        menu_icon: userInjured,
        menu_style: "m-child-0"
    },
    {
        id: 1,
        menu_text: "CREW LIST",
        menu_icon: users,
        menu_style: "m-child-1"
    },
    {
        id: 2,
        menu_text: "SHA-PLAN",
        menu_icon: fileMedical,
        menu_style: "m-child-2"
    },
    {
        id: 3,
        menu_text: "CHEMICALS",
        menu_icon: exclamationTriangle,
        menu_style: "m-child-3"
    },
    {
        id: 4,
        menu_text: "ENVIRONMENT",
        menu_icon: enviraLeaf,
        menu_style: "m-child-4"
    },
    {
        id: 5,
        menu_text: "PROTECTIVE INSPECTIONS",
        menu_icon: tasks,
        menu_style: "m-child-5"
    },

    {
        id: 6,
        menu_text: "RUH",
        menu_icon: exclamationCircle,
        menu_style: "m-child-6"
    },
    {
        id: 7,
        menu_text: "PROGRESS PLAN",
        menu_icon: poll,
        menu_style: "m-child-7"
    },
    {
        id: 8,
        menu_text: "HSE PLAN",
        menu_icon: medkit,
        menu_style: "m-child-8"
    },
    {
        id: 9,
        menu_text: "INFECTION CONTROL",
        menu_icon: shield,
        menu_style: "m-child-9"
    },
]
const menuStandard = [
    {
        id: 0,
        menu_text: "SECURE JOB ANALYSIS",
        menu_icon: userInjured,
        menu_style: "m-child-0"
    },
    {
        id: 1,
        menu_text: "CREW LIST",
        menu_icon: users,
        menu_style: "m-child-1"
    },
    {
        id: 2,
        menu_text: "SHA-PLAN",
        menu_icon: fileMedical,
        menu_style: "m-child-2"
    },
    {
        id: 3,
        menu_text: "CHEMICALS",
        menu_icon: exclamationTriangle,
        menu_style: "m-child-3"
    },
    {
        id: 5,
        menu_text: "PROTECTIVE INSPECTIONS",
        menu_icon: tasks,
        menu_style: "m-child-5"
    },
    {
        id: 6,
        menu_text: "RUH",
        menu_icon: exclamationCircle,
        menu_style: "m-child-6"
    },
    {
        id: 7,
        menu_text: "PROGRESS PLAN",
        menu_icon: poll,
        menu_style: "m-child-7"
    },
    {
        id: 8,
        menu_text: "HSE PLAN",
        menu_icon: medkit,
        menu_style: "m-child-8"
    },
]
const menuBasic = [
    {
        id: 0,
        menu_text: "SECURE JOB ANALYSIS",
        menu_icon: userInjured,
        menu_style: "m-child-0"
    },
    {
        id: 2,
        menu_text: "SHA-PLAN",
        menu_icon: fileMedical,
        menu_style: "m-child-2"
    },
    {
        id: 5,
        menu_text: "PROTECTIVE INSPECTIONS",
        menu_icon: tasks,
        menu_style: "m-child-5"
    },
    {
        id: 6,
        menu_text: "RUH",
        menu_icon: exclamationCircle,
        menu_style: "m-child-6"
    },
    {
        id: 7,
        menu_text: "PROGRESS PLAN",
        menu_icon: poll,
        menu_style: "m-child-7"
    },
    {
        id: 8,
        menu_text: "HSE PLAN",
        menu_icon: medkit,
        menu_style: "m-child-8"
    },
]

const configureStore = () => {
    const actions = {

    }
    initStore(actions, {
        menuAdvanced: menuAdvanced,
		menuStandard: menuStandard,
		menuBasic: menuBasic,
    })
}

export default configureStore
