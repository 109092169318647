import React, { useCallback, useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router';
import Swal from 'sweetalert2';

import Scheduler from '../../../helper/Schedulers';
import { useStore } from '../../../hooks-store/store';

import projectApi from '../../../api/ProjectApi';

const Content = (props) => {
	const [dataP, setDataP] = useState([]);
	const state = useStore()[0];
	const dispatch = useStore(false)[1];
	const history = useHistory();
	const { getProjectsDetail: getProject } = projectApi();
	const { getProjectMessages: getMessage } = projectApi();
	const { getOrganizationDetail: getOrganization } = projectApi();

	const getProjectMessage = useCallback(
		(param) => {
			getMessage({
				project: param,
				message_not_expired: false,
				ordering: '-is_pinned',
			})
				.then((res) => {
					const data = res.data;
					if (data) {
						data.results.sort((a, b) => (a.is_pinned > b.is_pinned) ? -1 : 1)
						dispatch('SET_MESSAGES', data.results);
					} else {
						dispatch('SET_MESSAGES', []);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		},
		[getMessage, dispatch]
	);

	const getProjectOrganization = useCallback(
		(param) => {
			getOrganization(param)
				.then((res) => {
					const data = res.data;
					if (data) {
						dispatch('SET_ORGANIZATION', data);
					} else {
						dispatch('SET_ORGANIZATION', []);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		},
		[getOrganization, dispatch]
	);

	const getProjectDetail = useCallback(
		(param) => {
			getProject(param)
				.then((res) => {
					const data = res.data;
					if (data) {
						if (data && data.template.id !== 1) {
							getProjectMessage(param);
						}
						if (data && data.organization) {
							getProjectOrganization(data.organization.id);
						}
						const newDataProject = { ...data };
						const sectionData = data.sections.filter((s, i) => i !== 2);
						newDataProject.sections = sectionData;
						setDataP(newDataProject);
						dispatch('SET_PROJECT', newDataProject);
					}
				})
				.catch((err) => {
					console.log(err);
					if (err && err.response) {
						let errText;
						if (err.response.status === 403) {
							errText = 'Sorry, you dont have access to this project';
						} else if (err.response.status === 401) {
							errText = 'Authentication Failed';
						} else {
							errText = 'There is problem with your project, please try to login again or contact Administrator';
						}
						let timerInterval;
						Swal.fire({
							title: 'Oops, something went wrong!',
							icon: 'error',
							html: `<p>${errText} </p><br/> Redirect in <b></b> seconds`,
							timer: 5000,
							timerProgressBar: true,
							didOpen: () => {
								Swal.showLoading();
								timerInterval = setInterval(() => {
									Swal.getHtmlContainer().querySelector('b').textContent = (Swal.getTimerLeft() / 1000).toFixed(0);
								}, 100);
							},
							willClose: () => {
								clearInterval(timerInterval);
							},
						}).then((result) => {
							if (result.dismiss === Swal.DismissReason.timer) {
								dispatch('LOGOUT');
								dispatch('UNSET_PROJECT');
								history.replace('/login');
							}
						});
					}
				});
		},
		[getProject, dispatch, getProjectMessage, getProjectOrganization, history]
	);

	useEffect(() => {
		if (state.isLoggedIn && state.project.key && state.project.project.id && !('sections' in state.dataProject) && dataP.length === 0) {
			getProjectDetail(state.project.project.id);
		}
	}, [state, getProjectDetail, dataP]);

	return (
		<>
			<Scheduler isRunning={state.project.project?.id} action={() => getProjectMessage(state.project.project?.id)} />
			{props.children}
		</>
	);
};

export default React.memo(withRouter(Content));
