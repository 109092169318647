import React from 'react'

import { useStore } from '../../../hooks-store/store';

import linkChecked from '../../../helper/UseLinkChecked';
import countAssetCounter from '../../../lib/countAssetCounter';
import styles from "./Tablet.module.css";

const Tablet = () => {
	const {setAssetCounter} = countAssetCounter()
	const state = useStore()[0]

	const data = state.dataProject;

	let sectionName, sectionImage;

	if (data && data.sections) {
		data?.sections?.forEach((dt) => {
			if (dt.category === "Image with link") {
				sectionName = dt.name;
				if (dt.assets) {
					if (dt.assets[0]?.asset_file) {
						if (dt.assets[0].asset_link) {
							const linkUrl = linkChecked(dt.assets[0].asset_link)
							sectionImage = (
								<a href={linkUrl} onClick={()=>setAssetCounter(dt.assets[0].id, dt.assets[0].asset_link)} alt={dt.name} target="_blank" rel="noopener noreferrer">
									<img src={dt.assets[0].asset_file} alt={dt.name} />
								</a>
							);
						} else {
							sectionImage = <img src={dt.assets[0].asset_file} alt={dt.name} />;
						}
					} else {
						sectionImage = false;
					}
				}
			}
		});
	}
	return (
		<>
			{
				data && data.sections &&
				<div className={styles.TabS5}>
					<div>
						{sectionImage ? sectionImage : <img src={`https://dummyimage.com/1920x200/ffb504/000/?text=+${sectionName}`} alt={"placeholder rig plan"} />}
					</div>
				</div>
			}
		</>
	)
}

export default React.memo(Tablet)
