import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

// pages //
import Home from "./pages/Home/Home";
import NotFound from "./pages/NotFound/NotFound";
import Login from "./pages/Login/Login";

//privateRoute
import PrivateRoute from "./SiteRoute/PrivateRoute";

class App extends Component {
	render() {
		return (
			<HelmetProvider>
				<Helmet>
					<title>DigiTavle</title>
				</Helmet>
				<div className="Dashboard">
					<Router>
						<Switch>
							<Route path="/preview-board/:organization/:name/:number/:id" exact component={Home} />
							<Route path="/board/:organization/:name/:number/:id" exact component={Home} />
							<Route path="/login" exact component={Login} />
							<PrivateRoute />
							<Route component={NotFound} />
						</Switch>
					</Router>
				</div>
			</HelmetProvider>
		);
	}
}

export default App;
