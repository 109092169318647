import Cookies from "js-cookie";
import { initStore } from "./store";

const configureStore = () => {
    const actions = {
        SET_PROJECT: (curState, dataProject) => {
            const isNorskExist = dataProject.languages.filter(l => l.id === 2)

            return {
                isProject: true,
                dataProject: dataProject,
                messages: curState.messages,
                organization: curState.organization,
                template: dataProject.template?.id,
                language: (isNorskExist && isNorskExist.length) ? isNorskExist[0]?.id : dataProject?.languages[0]?.id
            }
        },
        SET_MESSAGES: (curState, messages) => {
            return {
                isMessages: true,
                messages: messages,
            }
        },
        SET_ORGANIZATION: (curState, organization) => {
            return {
                isOrganization: true,
                organization: organization,
            }
        },
        SET_LANGUAGE: (curState, language) => {
            if (curState.language !== parseInt(language)) {
                Cookies.set("bdlg", parseInt(language));
                return {
                    language: parseInt(language),
                }
            }
        },
        UNSET_PROJECT: () => {
            return {
                isProject: false,
                dataProject: [],
                isMessages: false,
                messages: [],
                isOrganization: false,
                organization: [],
                language: 1,
                template: 1,
                isAuth: false,
                isPreview: "false"
            }
        }
    }

    initStore(actions, {
        isProject: false,
        dataProject: [],
        isMessages: false,
        messages: [],
        isOrganization: false,
        organization: [],
        language: Cookies.get("bdlg") || 1,
        template: 1,
        isAuth: false,
        isPreview: "false"
    })
}

export default configureStore