import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";

import { useStore } from '../../../hooks-store/store';
import linkChecked from '../../../helper/UseLinkChecked';
import countAssetCounter from '../../../lib/countAssetCounter';

import styles from "./Tablet.module.css";

const SampleNextArrow = (props) => {
	const { className, style, onClick } = props;
	return <div className={className} style={{ ...style, display: "block" }} onClick={onClick} />;
};

const SamplePrevArrow = (props) => {
	const { className, style, onClick } = props;
	return <div className={className} style={{ ...style, display: "block" }} onClick={onClick} />;
};

const Tablet = () => {
	const settings = {
		dots: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		fade: true,
		autoplay: true,
		autoplaySpeed: 5000,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
	};
	const {setAssetCounter} = countAssetCounter()
	const state = useStore()[0]

	const data = state.dataProject;

	let sectionName, sectionImage;
	if (data && data.sections) {
		data?.sections?.forEach((dt) => {
			if (dt.category === "Multiple images with link") {
				sectionName = "HMS";
				if (dt.assets) {
					sectionImage = dt.assets.map((ass, i) => {
						let html;
						if (ass.asset_file) {
							let content = (
								<div key={`${sectionName}-${i}`} className={styles.CardImgs}>
									<img src={ass.asset_file} alt={`slide1`} />
								</div>
							);
							if (ass.asset_link) {
								const linkUrl = linkChecked(ass.asset_link)
								html = (
									<a key={`${sectionName}-${i}`} onClick={()=>setAssetCounter(ass.id, ass.asset_link)} className={styles.aCardImgs} href={linkUrl} alt="" target="_blank" rel="noopener noreferrer">
										{content}
									</a>
								);
							} else {
								html = content;
							}
						}
						return html;
					});
				}
			}
		});
	}
	return (
		<div className={styles.Tabs6}>
			<div className={styles.TopSet}>
				<h5>{sectionName}</h5>
				<div className={styles.Icons}>
					<FontAwesomeIcon icon={faChevronRight} />
				</div>
			</div>
			<div className={styles.BtmSet}>
				<div className={styles.__slider} id="slider-customize-tab">
					<Slider {...settings}>
						{sectionImage ? sectionImage : <img className={styles.dummyImage} src={`https://dummyimage.com/820x820/ffb504/000/?text=+${sectionName}`} alt={"placeholder rig plan"} />}
					</Slider>
				</div>
			</div>
		</div>
	)
}

export default Tablet
