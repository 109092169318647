import React, { useContext } from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';

import { useStore } from '../../../hooks-store/store';

import Loader from '../../../components/Loader/Loader';

import Section1 from '../../../sections/Section1/Section1';
import Section2 from '../../../sections/Section2/Section2';
import Section3 from '../../../sections/Section3/Section3';
import Section4 from '../../../sections/Section4/Section4';
import Section5 from '../../../sections/Section5/Section5';
import Section6 from '../../../sections/Section6/Section6';
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';

import Context from '../../../Context';
import styles from '../Home.module.css';

const Container = styled.div`
	height: ${({ template, H, headH3, headHMain }) => (template === 3 ? `${H - (headH3 + 4)}px` : `${H - headHMain}px`)};
`;
const HomeTopSection = styled.div`
	height: ${({ bottomTopH }) => bottomTopH + 'px'};
	width: ${({ baseW }) => baseW + 'px'};
`;

const HomeBottomSection = styled.div`
	height: ${({ bottomLeftH }) => bottomLeftH + 'px'};
	width: ${({ baseW }) => baseW + 'px'};
`;

const LeftSection = styled.div`
	width: ${({ baseW }) => baseW + 'px'};
	height: ${({ template, H, headH3, headHMain }) => (template === 3 ? `${H - (headH3 + 4)}px` : `${H - headHMain}px`)};
`;

const RightSection = styled.div`
	width: ${({ baseWRightSection }) => baseWRightSection + 'px'};
`;

const BottomLeftSection = styled.div`
	height: ${({ bottomLeftH }) => bottomLeftH + 'px'};
	width: ${({ bottomLeftSectionW }) => bottomLeftSectionW + 'px'};
`;

const BottomCenterSection = styled.div`
	height: ${({ bottomLeftH }) => bottomLeftH - 16 + 'px'};
	width: ${({ baseW, bottomLeftSectionW }) => baseW - bottomLeftSectionW + 'px'};
`;

const Desktop = () => {
	const context = useContext(Context);
	const state = useStore()[0];
	const { w: W, h: H } = context;

	const template = state.template;

	let parentStyle, containerStyle, homeUp, homeBtm;
	if (template === 1) {
		containerStyle = 'container-basic';
		parentStyle = styles.ParentContainer1;
	} else if (template === 2) {
		containerStyle = 'container-standard';
		parentStyle = styles.ParentContainer2;
		homeUp = styles.homeUpStd;
		homeBtm = styles.homeBtmStd;
	}

	let headHMain = H > 1019 ? 112 : 88;
	let headH3 = H > 1019 ? 96 : 88;
	const LRSpace = W > 1024 ? 48 : 32;

	if (H > 480 && H <= 678) {
		headHMain = headH3 = 80;
	} else if (H <= 480) {
		headHMain = headH3 = 64;
	}

	const w = W - LRSpace;
	const leftSpace = w > 1024 ? 70 : 75;
	const baseW = (w * leftSpace) / 100;
	const baseWRightSection = (w * (100 - leftSpace)) / 100;
	const bottomLeftH = (H * 50) / 100;
	const bottomTopH = H - (bottomLeftH + (template === 3 ? headH3 : headHMain));
	const bottomLeftSectionW = 1.414 * bottomLeftH;

	return (
		<>
			{state.isProject && (template === 1 ? true : state.isMessages) && state.isOrganization ? (
				<>
					<Header />
					<Container template={template} H={H} headH3={headH3} headHMain={headHMain} className={['Container', containerStyle].join(' ')}>
						<div className={[styles.ParentContainer, parentStyle].join(' ')}>
							<LeftSection baseW={baseW} template={template} H={H} headH3={headH3} headHMain={headHMain} className={styles.LeftSection}>
								<HomeTopSection bottomTopH={bottomTopH} baseW={baseW} className={[styles.Up, homeUp].join(' ')}>
									<div className={styles.Section1}>
										<Section1 />
									</div>
									<div className={styles.Grouped}>
										<div className={styles.Section2}>
											<Section2 />
										</div>
										<div className={styles.Section3}>
											<Section3 />
										</div>
									</div>
								</HomeTopSection>
								<HomeBottomSection bottomLeftH={bottomLeftH} baseW={baseW} className={[styles.Bottom, homeBtm].join(' ')}>
									<BottomLeftSection bottomLeftH={bottomLeftH} bottomLeftSectionW={bottomLeftSectionW} className={styles.Section5}>
										<Section5 />
									</BottomLeftSection>
									{template === 3 && (
										<BottomCenterSection bottomLeftH={bottomLeftH} baseW={baseW} bottomLeftSectionW={bottomLeftSectionW} className={styles.Section6}>
											<Section6 />
										</BottomCenterSection>
									)}
								</HomeBottomSection>
							</LeftSection>
							{[2, 3].includes(template) && (
								<RightSection baseWRightSection={baseWRightSection} className={styles.RightSection}>
									{[2, 3].includes(template) && (
										<div className={styles.Section4}>
											<Section4 />
										</div>
									)}
									<Footer />
								</RightSection>
							)}
						</div>
					</Container>
				</>
			) : (
				<div className={styles.loaderContainer}>
					<Loader loading={true} />
				</div>
			)}
		</>
	);
};
export default withRouter(Desktop);
