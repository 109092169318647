import React, { useContext } from 'react';
import styled from 'styled-components';

import Context from '../../Context';
import linkChecked from '../../helper/UseLinkChecked';

const Span = styled.span`
	font-size: ${(props) => props.size + 'px'};
`;

function MenuItems(props) {
	const context = useContext(Context);

	const { h: H, w: W } = context;
	let linkUrl = linkChecked(props.Link);

	let textStyle = 16;
	switch (true) {
		case W >= 2560 && H >= 1080:
			textStyle = 22;
			break;
		case W >= 1920 && H >= 1080:
			textStyle = 16;
			break;
		case W >= 1600 && W < 1920 && H >= 900 && H < 1080:
			textStyle = 14;
			break;
		case W >= 1600 && W < 1920 && H >= 480 && H < 900:
			textStyle = 13;
			break;
		case (W >= 1280 && H < 1080 && H > 768) || (W >= 1280 && H > 990) || (W > 1366 && W < 1600 && H <= 1080):
			textStyle = 12;
			break;
		case (W >= 1024 && W < 1280 && H > 678 && H <= 768) || (W >= 1600 && W < 1920 && H < 480) || (W >= 1100 && W <= 1366 && H <= 1080):
			textStyle = 11;
			break;
		case W >= 1024 && H > 480 && H <= 678:
			textStyle = 10;
			break;
		case (W >= 900 && W < 1024 && H <= 768) || H <= 480 || W <= 768:
			textStyle = 9;
			break;
		case (W < 900 && H <= 1080):
			textStyle = 8;
			break;
		default:
			break;
	}

	return (
		<div
			className={['Cards', props.style].join(' ')}
			key={`menu-items-i-${props.Index}`}
			id={`menu-items-i-${props.Index}`}
			onClick={props.onClick}
		>
			{!linkUrl ? (
				<>
					<img src={props.Icon} alt='' />
					<Span size={textStyle}>{props.MenuText}</Span>
				</>
			) : (
				<>
					<a href={linkUrl} target='_blank' rel='noreferrer'>
						{props.link}
						<img src={props.Icon} alt='' />
						<Span size={textStyle}>{props.MenuText}</Span>
					</a>
				</>
			)}
		</div>
	);
}

export default MenuItems;
