import Cookies from "js-cookie";

let arr = {
    base_api: process.env.REACT_APP_API_URL,
    token: Cookies.get("key"),
    tokenDev: "0dc8907a481238f6501e9ff7144315877bb0ca82",
    GA_ID: process.env.REACT_APP_GA_ID
}

export const setArr = (newArr) => {
    arr = newArr
}
export default arr
