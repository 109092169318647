import React, { useContext, useEffect, useCallback, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';

import Context from '../../Context';
import styles from '../Header/Header.module.css';

const Span = styled.span`
	font-size: ${(props) => props.size + 'px'};
	line-height: ${(props) => (props.size > 28 ? props.size * 1.5 : props.size + 4) + 'px'};
`;

const Weather = (props) => {
	const context = useContext(Context);

	const { h: H, w: W } = context;

	let textStyle = H > 1019 ? 18 : 16;
	let textDegStyle = H > 1019 ? 32 : 24;

	switch (true) {
		case W >= 2560 && H >= 1080:
			textStyle = 22;
			textDegStyle = 32;
			break;
		case H < 1020 && H > 768:
			textStyle = 16;
			textDegStyle = 20;
			break;
		case H > 480 && H <= 678:
			textStyle = 14;
			textDegStyle = 18;
			break;
		case H <= 480:
			textStyle = 12;
			textDegStyle = 16;
			break;
		default:
			break;
	}

	const [weather, setWeather] = useState(false);
	const getWeather = useCallback(
		async (params) => {
			if (!weather) {
				await axios
					.get('https://api.met.no/weatherapi/locationforecast/2.0/compact.json', {
						params: params,
						headers: {
							Accept: 'application/json',
						},
					})
					.then((res) => {
						if (res.data) {
							let units = res.data.properties.meta.units;
							let timeseries = res.data.properties.timeseries;

							const weathers = timeseries.map((el, index) => {
								return {
									index: index,
									air_pressure: el.data.instant.details.air_pressure_at_sea_level + ' ' + units.air_pressure_at_sea_level,
									air_temperature: el.data.instant.details.air_temperature + ' ' + units.air_temperature,
									cloud_area_fraction: el.data.instant.details.cloud_area_fraction + ' ' + units.cloud_area_fraction,
									relative_humidity: el.data.instant.details.relative_humidity + ' ' + units.relative_humidity,
									wind_from_direction: el.data.instant.details.wind_from_direction + ' ' + units.wind_from_direction,
									wind_speed: el.data.instant.details.wind_speed + ' ' + units.wind_speed,
									precipitation_amount: el.data.next_6_hours
										? el.data.next_6_hours.details
											? el.data.next_6_hours.details.precipitation_amount
											: ''
										: ' ' + units.precipitation_amount,
									time: el.time,
									summary: el.data.next_6_hours ? (el.data.next_6_hours.summary ? el.data.next_6_hours.summary.symbol_code : '') : '',
								};
							});

							setWeather(weathers);
							// dispatch("SET_WEATHER", weathers);
						}
					})
					.catch((err) => console.log(err));
			}
		},
		[weather]
	);

	useEffect(() => {
		let weatherSubs = true;
		if (weatherSubs && !weather) {
			getWeather(props.position);
		}
		return () => {
			weatherSubs = false;
		};
	}, [getWeather, weather, props.position]);

	const temp = (data, CURRENTLY_MOMENT) => {
		let str;
		if (data) {
			data?.forEach((w) => {
				const times = w.time.substring(11, 13);
				if (times === CURRENTLY_MOMENT) {
					str = w.air_temperature.split(' ')[0];
				}
			});
		}
		return str;
	};

	const condition = (data, CURRENTLY_MOMENT) => {
		let str, str2, res;
		data.forEach((w) => {
			const times = w.time.substring(11, 13);
			if (times === CURRENTLY_MOMENT) {
				if (w.summary) {
					str = w.summary.split('_')[0].charAt(0).toUpperCase();
					str2 = w.summary.split('_')[0].substr(1, w.summary.length);
					res = str + str2 + ' Day';
				} else {
					res = '';
				}
			}
		});
		return res;
	};

	const wind = (data, CURRENTLY_MOMENT) => {
		let winds;
		data.forEach((w) => {
			const times = w.time.substring(11, 13);
			if (times === CURRENTLY_MOMENT) {
				if (w.wind_speed) {
					winds = w.wind_speed;
				} else {
					winds = '';
				}
			}
		});
		return winds;
	};

	let date = new Date();
	let options = {
		weekday: 'long',
		year: 'numeric',
		month: 'short',
		day: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
	};

	const CURRENT_TIME_LOCAL_STRING = date.toLocaleTimeString('no-NO', options);
	const CURRENTLY_MOMENT = moment().utc(CURRENT_TIME_LOCAL_STRING).toISOString().substring(11, 13);

	let currentWeather = (
		<>
			{weather && (
				<>
					<div className={styles.d}>
						<Span size={textDegStyle}>{temp(weather, CURRENTLY_MOMENT) + '°'}</Span>
					</div>
					<div className={styles.e}>
						{Math.floor(temp(weather, CURRENTLY_MOMENT)) < 30 ? (
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='16'
								height='16'
								fill='currentColor'
								className='bi bi-cloud-sun'
								viewBox='0 0 16 16'
							>
								<g fill={props.color}>
									<path d='M7 8a3.5 3.5 0 0 1 3.5 3.555.5.5 0 0 0 .624.492A1.503 1.503 0 0 1 13 13.5a1.5 1.5 0 0 1-1.5 1.5H3a2 2 0 1 1 .1-3.998.5.5 0 0 0 .51-.375A3.502 3.502 0 0 1 7 8zm4.473 3a4.5 4.5 0 0 0-8.72-.99A3 3 0 0 0 3 16h8.5a2.5 2.5 0 0 0 0-5h-.027z' />
									<path d='M10.5 1.5a.5.5 0 0 0-1 0v1a.5.5 0 0 0 1 0v-1zm3.743 1.964a.5.5 0 1 0-.707-.707l-.708.707a.5.5 0 0 0 .708.708l.707-.708zm-7.779-.707a.5.5 0 0 0-.707.707l.707.708a.5.5 0 1 0 .708-.708l-.708-.707zm1.734 3.374a2 2 0 1 1 3.296 2.198c.199.281.372.582.516.898a3 3 0 1 0-4.84-3.225c.352.011.696.055 1.028.129zm4.484 4.074c.6.215 1.125.59 1.522 1.072a.5.5 0 0 0 .039-.742l-.707-.707a.5.5 0 0 0-.854.377zM14.5 6.5a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z' />
								</g>
							</svg>
						) : (
							<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-sun' viewBox='0 0 16 16'>
								<g fill={props.color}>
									<path d='M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z' />
								</g>
							</svg>
						)}
					</div>
					<div className={styles.f}>
						<Span size={textStyle} className={styles.loc}>
							{props.locationCity},&nbsp;
							{condition(weather, CURRENTLY_MOMENT)}
						</Span>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								marginTop: '.2rem',
							}}
						>
							<Span>wind :{wind(weather, CURRENTLY_MOMENT)}</Span>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='23'
								height='23'
								fill='currentColor'
								className='bi bi-arrow-down'
								viewBox='0 0 23 23'
							>
								<g fill={props.color}>
									<path
										fillRule='evenodd'
										d='M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z'
									/>
								</g>
							</svg>
						</div>
					</div>
				</>
			)}
		</>
	);
	let weathers;
	if (props.locationCity !== 'Your City') {
		if (props.position) {
			weathers = (
				<a
					className={styles.aLink}
					href={`https://www.yr.no/nb/detaljer/tabell/${props.position.lat},${props.position.lon}`}
					target='_blank'
					rel='noreferrer'
				>
					{currentWeather}
				</a>
			);
		} else {
			weathers = currentWeather;
		}
	} else if (props.locationCity === 'Your City') {
		weathers = currentWeather;
	}

	return (
		<div className={styles.a_1} style={{ color: props.color }}>
			{weather && <>{CURRENT_TIME_LOCAL_STRING && CURRENTLY_MOMENT && temp(weather, CURRENTLY_MOMENT) && <>{weathers}</>}</>}
		</div>
	);
};

export default React.memo(Weather);
