import { initStore } from "./store";

const configureStore = () => {
    const actions = {
        SET_LANGUAGE_ITEM: (curState, language) => {
            return {
                languageItem: curState.languageItem
            }
        },
    }

    initStore(actions, {
        languageItem: [{
            language: 1,
            content: {
                header: {
                    logout: "LOGOUT"
                },
                section1: {
                    fire: "Fire",
                    police: "Police",
                    ambulance: "Ambulance",
                    cp_event: "Contact person on event",
                },
                section4: {
                    Messages: "Messages",
                    messages: "messages"
                }
            }
        },
        {
            language: 2,
            content: {
                header: {
                    logout: "LOGG UT"
                },
                section1: {
                    fire: "Brann",
                    police: "Politi",
                    ambulance: "Ambulanse",
                    cp_event: "Kontaktperson ved alvorlig hendelse"
                },
                section4: {
                    Messages: "Meldinger",
                    messages: "meldinger"
                }
            }
        }],
    })
}

export default configureStore