import React, { useContext } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { useStore } from '../../hooks-store/store';

import Weather from '../Weather/Weather';
import Context from '../../Context';

import loginApi from '../../api/LoginApi';

import styles from './Header.module.css';

const Span = styled.span`
	font-size: ${({ size }) => size + 'px'};
	line-height: ${({ size }) => size + 4 + 'px'};
`;

const Navbar = styled.div`
	height: ${({ template, headH3, headHMain }) => (template === 3 ? headH3 + 'px' : headHMain + 'px')};
	background: ${({ board_background_color }) => board_background_color};
	color: ${({ board_content_color }) => board_content_color};
	padding: ${({ template }) => (template === 3 ? '0.5rem 0' : '1rem 0')};
`;

function HeaderDesktop(props) {
	const context = useContext(Context);
	const state = useStore()[0];
	const dispatch = useStore(false)[1];
	const history = useHistory();
	const { h: H, w: W } = context;

	const { signOut: logOut } = loginApi();

	const handleLogout = () => {
		logOut().then((res) => {
			if (res) {
				dispatch('LOGOUT');
				dispatch('UNSET_PROJECT');
				history.replace('/login');
			}
		});
	};

	const p = state.dataProject;
	const o = state.organization;
	const lang = p?.languages?.map((language, index) => {
		return (
			<option key={`${language.name}-${language.id}`} value={language.id}>
				{language.name}
			</option>
		);
	});

	const setLanguage = (language) => {
		dispatch('SET_LANGUAGE', language);
	};

	const template = p?.template?.id;
	let wrapperStyle;
	if (template === 1) {
		wrapperStyle = styles.wrapper1;
	} else if (template === 2) {
		wrapperStyle = styles.wrapper2;
	}

	let location, locationCity;
	if (p?.latitude && p?.longitude) {
		location = {
			lat: p?.latitude,
			lon: p?.longitude,
		};
		locationCity = p?.location_name ? p.location_name : 'Your City';
	}

	let selectedLang = state.language;
	if (p?.languages?.length) {
		const isActive = p?.languages.find((l) => l.id === parseInt(selectedLang));
		selectedLang = isActive ? isActive.id : p?.languages[0].id;
	}

	let headHMain = H > 1019 ? 112 : 88;
	let headH3 = H > 1019 ? 96 : 88;

	let textStyle = H > 1079 ? 18 : 16;
	switch (true) {
		case W >= 2560 && H >= 1080:
			textStyle = 22;
			break;
		case W >= 1920 && H >= 1020:
			textStyle = 18;
			break;
		case H < 1080 && H > 768:
			textStyle = 14;
			break;
		case H > 480 && H <= 678:
			headHMain = headH3 = 80;
			textStyle = 12;
			break;
		case H <= 480:
			headHMain = headH3 = 64;
			textStyle = 12;
			break;
		default:
			break;
	}

	const logo = o?.logo ? (
		<img src={o.logo} alt={styles.logo} />
	) : (
		<img src={'https://dummyimage.com/600x400/ffb504/000/?text=Your+Logo'} alt='a' />
	);
	const orgName = o?.name ? <Span size={textStyle}>{o.name}</Span> : <Span size={textStyle}>Organization Name</Span>;
	const orgNumber = o?.number ? <Span size={textStyle}>{o.number}</Span> : <Span size={textStyle}>Organization Number</Span>;
	const projectName = p?.name ? <Span size={textStyle}>{p.name}</Span> : <Span size={textStyle}>Project Name</Span>;
	const board_background_color = o?.board_background_color
		? o.board_background_color
		: o.board_background_color === null
		? '#1a4a97'
		: o.board_background_color;
	const board_content_color = o?.board_content_color
		? o.board_content_color
		: o.board_content_color === null
		? '#ffffff'
		: o.board_content_color;

	let btnLogoutStyle = {
		color: board_background_color,
		background: board_content_color,
		border: `1px solid ${board_content_color}`,
	};

	return (
		<>
			<Navbar
				template={template}
				headH3={headH3}
				headHMain={headHMain}
				board_background_color={board_background_color}
				board_content_color={board_content_color}
				className={[styles.navbar, props.sticky].join(' ')}
				style={{ background: board_background_color, color: board_content_color }}
			>
				<div className={[styles.wrapper, wrapperStyle].join(' ')}>
					<div className={styles.part}>
						<div className={styles.a}>
							<div className={styles.b}>
								<div className={styles.bL}>{logo}</div>
							</div>
							<div className={styles.c}>
								<>{orgName}</>
								<>{orgNumber}</>
								<>{projectName}</>
							</div>
						</div>
					</div>
					<div className={styles.part}>
						{location && (
							<>
								<Weather position={location} locationCity={locationCity} color={board_content_color} />
							</>
						)}
					</div>
					<div className={styles.part}>
						<div className={styles.a}>
							<div className={styles.g}>
								{lang && lang.length > 0 && (
									<select
										className={styles.SelectLang}
										onChange={(e) => setLanguage(e.target.value)}
										style={{ color: board_content_color, fontSize: textStyle + 'px' }}
										defaultValue={selectedLang}
									>
										{lang}
									</select>
								)}
							</div>
							<div className={styles.c}>
								<button className={styles.btn_logout} style={btnLogoutStyle} onClick={handleLogout}>
									<Span size={textStyle}>LOGOUT</Span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</Navbar>
		</>
	);
}

export default React.memo(withRouter(HeaderDesktop));
