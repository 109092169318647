import React from "react";

import DesktopView from "./DesktopView/Desktop";

const Section1 = React.memo(() => {
	return (
		<DesktopView />
	)
})

export default Section1;


