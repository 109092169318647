import {useReducer} from 'react'

const initialInputState = {
    value: "",
    isTouched: false
}

const inputStateReducer = (state, action) => {
    if (action.type === "INPUT"){
        return { value: action.value, isTouched: state.isTouched }
    }
    if (action.type === "BLUR"){
        return { isTouched: true, value: state.value }
    }
    if (action.type === "RESET"){
        return { isTouched: false, value: "", isError: false }
    }
    if (action.type === "ERROR"){
        return { isError: true, isTouched: state.isTouched, value: state.value }
    }

    return inputStateReducer
}

const useInput = (validateValue) => {
    const [inputState, dispatch] = useReducer(inputStateReducer, initialInputState)

    const valueIsValid = validateValue(inputState.value)
    const isTouched = inputState.isTouched
    const hasError = (!valueIsValid && inputState.isTouched)
    const forcedError = inputState.isError

    const valueChangeHandler = (event) => {
        dispatch({ type: "INPUT", value: event.target.value})
    }

    const inputBlurHandler = () => {
        dispatch({type: "BLUR"})
    }

    const reset = () => {
        dispatch({type: "RESET"})
    }

    const setError = () => {
        dispatch({type: "ERROR"})
    }

    return {
        value: inputState.value,
        isValid: valueIsValid,
        isTouched: isTouched,
        hasError,
        forcedError,
        valueChangeHandler,
        inputBlurHandler,
        reset,
        setError
    }
}

export default useInput