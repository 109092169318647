import React, { useContext } from 'react';
import { Slide } from 'react-awesome-reveal';
import styled from 'styled-components';

import { useStore } from '../../../hooks-store/store';

import linkChecked from '../../../helper/UseLinkChecked';
import countCounter from '../../../lib/countCounter';
import Context from '../../../Context';

import styles from './Desktop.module.css';

const Span = styled.span`
	font-size: ${(props) => props.size + 'px'};
`;

const Button = styled.button`
	margin-top: ${({ H }) => (H >= 1080 ? 0.5 : H < 1080 && H > 768 ? 0.25 : 0.15) + 'rem'};
	border-radius: 6px;
`;

const DesktopView = () => {
	const context = useContext(Context);

	const { setCounter } = countCounter();
	const state = useStore()[0];

	const { h: H, w: W } = context;
	const data = state.dataProject;
	const language = state.language;
	const o = state.organization;

	const public_background_color = o?.public_background_color ? o.public_background_color : '#1a4a97';
	const public_content_color = o?.public_content_color ? o.public_content_color : '#ffffff';

	let textStyle = H > 1019 ? 18 : 16;

	switch (true) {
		case W >= 2560 && H >= 1080:
			textStyle = 22;
			break;
		case (H < 1020 && H > 768) || (W >= 1280 && H >= 768 && H < 1020):
			textStyle = 14;
			break;
		case W >= 1024 && W < 1280 && H > 678 && H <= 768:
			textStyle = 12;
			break;
		case H >= 480 && W < 1180 && H <= 768:
			textStyle = 10;
			break;
		case H > 480 && H <= 678:
			textStyle = 12;
			break;
		case H <= 480:
			textStyle = 10;
			break;
		default:
			break;
	}

	let documentation;
	if (data && data.sections) {
		if (data.sections[1]) {
			const sec = data.sections[1];
			documentation = sec?.text_holders?.map((s, i) => {
				let activeLangH = s.text_holder_languages?.findIndex((la) => la.language === language);
				let activeLangC = s.text_contents?.findIndex((la) => la.language === language);
				activeLangH = String(activeLangH) ? activeLangH : 0;
				activeLangC = String(activeLangC) ? activeLangC : 0;

				const c = s.text_holder_languages[activeLangH];
				const l = s.text_contents[activeLangC];
				const btnContent = c ? (c.editable_text_holder ? c.editable_text_holder : c.name) : '';
				let content = null;
				let html = (
					<Button
						H={H}
						key={`${l?.content}-${i}`}
						className={styles.btn}
						style={{ background: public_background_color, color: public_content_color }}
						onClick={() => setCounter(l?.id)}
					>
						<Span size={textStyle}>{btnContent}</Span>
					</Button>
				);
				if (l?.content) {
					const link = linkChecked(l.content);
					content = (
						<a key={`${l.id}-${i}`} href={link} target='_blank' rel='noopener noreferrer'>
							{html}
						</a>
					);
				} else {
					content = html;
				}

				if (!s.text_contents[activeLangC]?.is_hidden) {
					return content;
				} else {
					return <></>;
				}

			});
		}
	}
	return (
		<Slide
			style={{
				width: '100%',
				display: 'flex',
				justifyContent: 'center',
				height: '100%',
			}}
			triggerOnce
		>
			<div className={styles.MenuList}>{documentation}</div>
		</Slide>
	);
};

export default React.memo(DesktopView);
