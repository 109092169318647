import React from "react";
import { withRouter, useHistory } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";

import { useStore } from "../../hooks-store/store";

import Weather from "../Weather/Weather";

import loginApi from "../../api/LoginApi";

import styles from "./Mobile.module.css";


function HeaderMobile(props) {
	const state = useStore()[0]
	const dispatch = useStore(false)[1]
	const { signOut: logOut } = loginApi()
	const history = useHistory();

	const handleLogout = () => {
		logOut().then((res) => {
			if (res) {
				dispatch("LOGOUT")
				dispatch("UNSET_PROJECT")
				history.replace("/login")
			}
		});
	};

	const p = state.dataProject;
	const o = state.organization;
	const lang = p?.languages?.map((language, index) => {
		return (
			<option key={`${language.name}-${language.id}`} value={language.id}>
				{language.name}
			</option>
		);
	});

	const setLanguage = (language) => {
		dispatch("SET_LANGUAGE", language);
	}

	const logo = o?.logo ? <img src={o.logo} alt={styles.logo} /> : <img src={"https://dummyimage.com/600x400/ffb504/000/?text=Your+Logo"} alt="a" />;
	const orgName = o?.name ? <span>{o.name}</span> : <span>Organization Name</span>;
	const orgNumber = o?.number ? <span>{o.number}</span> : <span>Organization Number</span>;
	const projectName = p?.name ? <span>{p.name}</span> : <span>Project Name</span>;
	const board_background_color = o?.board_background_color ? o.board_background_color : (o.board_background_color === null ? "#1a4a97" : o.board_background_color);
	const board_content_color = o?.board_content_color ? o.board_content_color : (o.board_content_color === null ? "#ffffff" : o.board_content_color);

	let logoStyle = {
		color: board_content_color,
		borderRight: `1px solid ${board_content_color}`,
	};

	// const template = p?.template?.id;
	// let wrapperStyle;
	// if (template === 1) {
	// 	wrapperStyle = styles.wrapper1;
	// } else if (template === 2) {
	// 	wrapperStyle = styles.wrapper2;
	// }

	let location, locationCity;
	if (p?.latitude && p?.longitude) {
		location = {
			lat: p?.latitude,
			lon: p?.longitude,
		};
		locationCity = p?.location_name ? p.location_name : "Your City";
	}

	let selectedLang = state.language
	if (p?.languages?.length) {
		const isActive = p?.languages.find(l => l.id === parseInt(selectedLang))

		selectedLang = isActive ? isActive.id : p?.languages[0].id
	}

	return (
		<>
			{
				state.isProject && state.isOrganization && lang &&
				<div className={styles.mobileHeader} style={{ background: board_background_color, color: board_content_color }}>
					<div className={styles.contentHeader}>
						<div className={styles.contentTop}>
							<div className={styles.logo} style={logoStyle}>
								{logo}
							</div>
							<div className={styles.organization}>
								<div className="d-flex align-items-center">
									{orgName}&nbsp;-&nbsp;
									{orgNumber}
								</div>
								{projectName}
							</div>
						</div>
						<div className={styles.logout}>
							<FiLogOut onClick={handleLogout} />
						</div>
					</div>

					<div className={styles.contentHeader}>
						<div className={styles.weather}>
							{location && (
								<>
									<Weather position={location} locationCity={locationCity} color={board_content_color} />
								</>
							)}
						</div>
						<div className={styles.lang}>
							<select className={""} onChange={(e) => setLanguage(e.target.value)} style={{ color: board_content_color }} defaultValue={selectedLang}>
								{lang}
							</select>
						</div>
					</div>
				</div>
			}
		</>
	);
}

export default React.memo(withRouter(HeaderMobile));
