import projectApi from "../api/ProjectApi";

const countCounter = () => {
    const { postContentCounter } = projectApi()

	const setCounter = (id) => {
		if (id){
			postContentCounter({
				text_content: id
			})
				.then((res) => {
					// do something
				})
				.catch((err) => {
					console.log(err.response);
				});
		}
	}

    return {setCounter}
}
export default countCounter