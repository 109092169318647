import React from 'react'
import { Slide } from "react-awesome-reveal";

import { useStore } from '../../../hooks-store/store';

import linkChecked from '../../../helper/UseLinkChecked';
import countAssetCounter from '../../../lib/countAssetCounter';
import styles from "./Desktop.module.css";

const Desktop = () => {
	const {setAssetCounter} = countAssetCounter()
	const state = useStore()[0]

	const data = state.dataProject;

	let sectionName, sectionImage;
	let sectionStyle;
	if (data && data.sections) {
		data?.sections?.forEach((dt) => {
			if (dt.category === "Image with link") {
				sectionName = dt.name;
				if (dt.assets) {
					if (dt.assets[0]?.asset_file) {
						if (dt.assets[0]?.asset_link) {
							const linkUrl = linkChecked(dt.assets[0].asset_link)
							sectionImage = (
								<a href={linkUrl} onClick={()=>setAssetCounter(dt.assets[0].id, dt.assets[0].asset_link)} alt={dt.name} target="_blank" rel="noopener noreferrer">
									<img src={dt.assets[0].asset_file} alt={dt.name} loading="lazy" />
								</a>
							);
						} else {
							sectionImage = <img src={dt.assets[0]?.asset_file} alt={dt.name} loading="lazy" />;
						}
					} else {
						sectionImage = false
					}
				}
			}
		});
		const template = data.template.id;
		if (template === 1) {
			sectionStyle = styles.Board5Basic;
		} else if (template === 2) {
			sectionStyle = styles.Board5Standard;
		}
	}
	return (
		<Slide
			style={{
				width: "100%",
				height: "100%",
			}}
			triggerOnce
		>
			<div className={[styles.Board5, sectionStyle].join(" ")}>
				<div className={styles.Btm5}>
					{sectionImage ? sectionImage : <img src={`https://dummyimage.com/1920x200/ffb504/000/?text=+${sectionName}`} alt={"placeholder rig plan"} loading="lazy" />}
				</div>
			</div>
		</Slide>
	)
}

export default React.memo(Desktop)
