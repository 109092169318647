import axios from "axios";
import config from "../lib/Config";
import { useStore } from "../hooks-store/store";

const ProjectApi = () => {
	const base_api = config.base_api;
	const state = useStore()[0]

	const getProjectsDetail = (id) => {
		return axios.get(base_api + `projects/${id}/`, {
			params: {},
			headers: {
				Authorization: "Token " + state.project.key,
				"Content-Type": "application/json",
			},
		});
	}

	const getProjectMessages = (params) => {
		return axios.get(base_api + `messages/`, {
			params: params,
			headers: {
				Authorization: "Token " + state.project.key,
				"Content-Type": "application/json",
			},
		});
	}

	const getOrganizationDetail = (id) => {
		return axios.get(base_api + `organizations/${id}/`, {
			params: {},
			headers: {
				Authorization: "Token " + state.project.key,
				"Content-Type": "application/json",
			},
		});
	}

	const postContentCounter = (data) => {
		return axios.post(base_api + `content_counters/`, data,{
			params: {},
			headers: {
				Authorization: "Token " + state.project.key,
				"Content-Type": "application/json",
			},
		});
	}

	const postAssetCounter = (data) => {
		return axios.post(base_api + `asset_counters/`, data,{
			params: {},
			headers: {
				Authorization: "Token " + state.project.key,
				"Content-Type": "application/json",
			},
		});
	}

	return { getProjectsDetail, getProjectMessages, getOrganizationDetail, postContentCounter, postAssetCounter }
}

export default ProjectApi;

