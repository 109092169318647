import { initStore } from "./store";

const configureStore = () => {
    const actions = {
        SET_WEATHER: (curState, weather) => {
            if (weather){
                return {
                    weathers: weather,
                    isWeather: true
                }
            }
        },
    }

    initStore(actions, {
        weathers: false,
        isWeather: false
    })
}

export default configureStore