import { useState, useEffect } from 'react'
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga4";
import slugify from 'react-slugify';

import useInput from '../../hooks/use-input';
import { useStore } from "../../hooks-store/store"

import InputMotion from "../../components/Input/InputMotion";
import loginApi from "../../api/LoginApi";

import classes from "./Login.module.css";

const errDescription = {
    projectNumberDesc: "Used to log in to your user account",
    passDesc: "At least 8 characters in total",
}

const isNotEmpty = (value) => value.trim() !== ""
const isNotEmptyPass = (value) => value.trim() !== "" && value.trim().length > 3
// const isEmail = (value) => value.includes("@")

const Login = (props) => {
    const [isErrorTextPass, setIsErrorTextPass] = useState(false)
    const [isErrorTextProject, setIsErrorTextProject] = useState(false)
    const { signIn: setLoggedIn } = loginApi()
    const dispatch = useStore(false)[1]

    const {
        value: projectNumberValue,
        isValid: projectNumberValid,
        isTouched: projectNumberTouched,
        hasError: projectNumberHasError,
        forcedError: projectNumberFError,
        valueChangeHandler: projectNumberChangeHandler,
        inputBlurHandler: projectNumberBlurHandler,
        reset: resetProjectNumber,
        setError: projectSetError
    } = useInput(isNotEmpty)

    const {
        value: passwordValue,
        isValid: passwordValid,
        isTouched: passwordTouched,
        hasError: passwordHasError,
        forcedError: passwordFError,
        valueChangeHandler: passwordChangeHandler,
        inputBlurHandler: passwordBlurHandler,
        reset: resetpassword,
        setError: passwordSetError
    } = useInput(isNotEmptyPass)

    let formIsValid = false;

    if (projectNumberValid && passwordValid) {
        formIsValid = true;
    }

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Login | DigiTavle" });
    }, [])

    const loggIn = event => {
        event.preventDefault();

        if (!formIsValid) {
            return;
        }

        const data = {
            username: projectNumberValue,
            password: passwordValue,
        };

        setLoggedIn(data)
            .then((res) => {
                const dt = res.data;
                if (dt) {
                    dt.isPreview = "true"
                    dispatch('LOGIN', dt);

                    resetProjectNumber()
                    resetpassword()

                    props.history.replace(`board/${slugify(dt.project.organization)}/${slugify(dt.project.name)}/${slugify(dt.project.number)}/${dt.project.id}`)
                }
            })
            .catch((err) => {
                console.log(err.response);

                const res = err.response;
                if (err && res) {
                    let errText, projectErr, passwordErr
                    if (res.data.detail && res.data.detail.length) {
                        const val = res.data.detail[0]
                        if (val.includes('not active')) {
                            projectErr = val
                            projectSetError()
                            setIsErrorTextProject(projectErr);
                        }
                        if (val.includes('credentials')) {
                            passwordErr = val
                            passwordSetError()
                            setIsErrorTextPass(passwordErr);
                        }
                    } else {
                        errText = "Either Project Number or password is invalid"
                        projectSetError()
                        setIsErrorTextProject(errText);

                    }

                }
            });
    };

    const goToManagementSite = () => {
        window.open(process.env.REACT_APP_WEB_URL)
    }

    const handleKeyPress = (e) => {
        if (formIsValid && e.key === "Enter") {
            loggIn(e);
        }
    };

    return (
        <>
            <form onSubmit={loggIn} autoComplete="off">
                <div className={classes.logo}>
                    <img src={require("./logoB.png").default} alt="" />
                </div>
                <div className={classes.login}>
                    <div className={classes.contentLogin}>
                        <h1>Show Board</h1>
                        <div className="mb-3">
                            <InputMotion id="projectNumber" label="Project Number" important={true} value={projectNumberValue} desc={isErrorTextProject ? isErrorTextProject : errDescription.projectNumberDesc} status={!projectNumberTouched ? true : (projectNumberFError ? false : projectNumberValid)} onChange={projectNumberChangeHandler} onBlur={projectNumberBlurHandler} hasError={projectNumberHasError} />
                        </div>
                        <div className="mb-5">
                            <InputMotion id="password" label="Board Password" type="password" important={true} value={passwordValue} desc={isErrorTextPass ? isErrorTextPass : errDescription.passDesc} onEnter={handleKeyPress} status={!passwordTouched ? true : (passwordFError ? false : passwordValid)} onChange={passwordChangeHandler} onBlur={passwordBlurHandler} hasError={passwordHasError} />
                        </div>

                        <button className={[classes.signIn, !formIsValid && classes.bimNotAllowed].join(" ")} onClick={loggIn}>
                            Show Board
                        </button>
                        <span>When you log in, a cookie will be stored in your browser which is used to remember that you are logged in. That is it's sole purpose and will be deleted when you log out again</span>

                        <div className="d-flex justify-content-center mb-2">
                            <span>
                                <strong>or</strong>
                            </span>
                        </div>
                        <div className={[classes.signIn].join(" ")} onClick={goToManagementSite}>
                            SIGN IN TO MANAGEMENT SYSTEM
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default withRouter(Login)


