import React from "react";
import digibimLogo from "../../assets/DIGIBIM_Logo.png"

import { useStore } from "../../hooks-store/store";

function Footer(props) {
	const state = useStore()[0]
	return (
		<>
			{
				state.isProject && state.isOrganization && state.language &&
				<div className="Footer">
					<div className="Div">
						<a href="https://digibim.no/" target="_blank" rel="noreferrer">
							<span>DIGIBIM.info by</span>
							<img src={digibimLogo} alt="" />
						</a>
					</div>
				</div>
			}
		</>
	);
}

export default Footer;
